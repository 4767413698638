<template>
  <r-page page-title="Company">
    <template #page-header>
      <h2 class="page-title">Company</h2>
      <r-menu-tab :level="2" :showed-menus="3"></r-menu-tab>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <div>List Companies</div>
        </div>
        <div class="level-right">
          <div
            class="filter-active role-access"
            v-if="packageArray.length !== 0"
          >
            Package +{{ packageArray.length }}
            <span>
              <b-icon
                class="click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearFilter('package')"
              />
            </span>
          </div>
          <div class="filter-active status" v-if="statusArray.length !== 0">
            Status +{{ statusArray.length }}
            <span>
              <b-icon
                class="click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearFilter('status')"
              />
            </span>
          </div>
          <b-button
            icon-left="filter-outline"
            class="mx-2"
            type="is-light"
            @click.native="openFilter()"
          >
            <img
              src="../../../../public/images/filter-dot.svg"
              v-if="statusArray.length !== 0 || packageArray.length !== 0"
              class="filter-dot-button"
            />
            Filter
          </b-button>
          <GeneralFilter
            :isFilterOpen="isFilterOpen"
            :packages="packageFilter"
            :statusList="statuses"
            @closeFilter="closeFilter"
            @filterPackages="filterByPackages($event)"
            @filterStatus="filterByStatus($event)"
            @filterBy="filterBy($event)"
            @reset="resetStateFilter()"
            @loadData="loadFilter()"
          ></GeneralFilter>
          <b-input
            type="is-light"
            placeholder="Search"
            icon-right="magnify"
            icon-right-clickable
            @icon-right-click="searchIconClick(search)"
            @keydown.native.enter="searchIconClick(search)"
            v-model="search"
          ></b-input>
        </div>
      </div>
    </template>
    <template #page-content>
      <b-table
        :data="getListCompaniesData"
        :per-page="perPage"
        @page-change="onPageChange"
        ref:table
        hoverable
        paginated
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        class="table-list-companies"
        @sort="onSort"
        :sticky-header="stickyHeaders"
        :pagination-simple="isPaginationSimple"
      >
        <template>
          <b-table-column field="id" v-slot="props" label="No." width="5%">
            <span>{{ props.index + 1 }}</span>
          </b-table-column>
          <b-table-column v-slot="props" label="Name Company" width="20%">
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column v-slot="props" label="Owner" width="15%">
            <span>{{ props.row.ownerName }}</span>
          </b-table-column>
          <b-table-column v-slot="props" label="Member" width="15%">
            <span>
              {{
                textSubString(props.row.totalUsers, 20, '...') + ' ' + 'User'
              }}
            </span>
          </b-table-column>
          <b-table-column v-slot="props" label="Package" width="15%">
            <span>{{ props.row.package }}</span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="date_joined"
            label="Date Joined"
            sortable
            width="15%"
          >
            <span>{{ formatDate(props.row.joinDate, 'DD MMMM YYYY') }}</span>
          </b-table-column>
          <b-table-column v-slot="props" label="Status" width="15%">
            <span
              :class="
                props && props.row.isActive ? 'active-class' : 'inactive-class'
              "
            >
              {{ props.row.isActive ? 'Active' : 'Inactive' }}
              <!-- {{ props.row.isActive }} -->
            </span>
          </b-table-column>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No data found. Please search and select the company first.</p>
            </div>
          </section>
        </template>
      </b-table>
    </template>
  </r-page>
</template>

<script>
// import ListCompaniesFilter from '../../../components/FilterContent/ListCompaniesFilter.vue'
import GeneralFilter from '../../../components/FilterContent/GeneralFilter.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { formatDate } from '@/services/util'

export default {
  components: {
    // ListCompaniesFilter,
    GeneralFilter,
  },
  data() {
    return {
      data: '',
      isPaginationSimple: true,
      perPage: 6,
      isAnnouncementLoading: false,
      search: null,
      isFilterOpen: false,
      page: 0,
      lastPage: 0,
      total: 0,
      sortField: 'id',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      filterPackage: [],
      filterStatus: [],
      packageFilter: [
        {
          title: 'Small Business',
          value: 'small business',
          isSelected: false,
        },
        {
          title: 'Medium Business',
          value: 'medium business',
          isSelected: false,
        },
        {
          title: 'Company',
          value: 'company',
          isSelected: false,
        },
        {
          title: 'Enterprise',
          value: 'enterprise',
          isSelected: false,
        },
      ],
      statuses: [
        {
          title: 'Active',
          value: '1',
          isSelected: false,
        },
        {
          title: 'Inactive',
          value: '0',
          isSelected: false,
        },
      ],
      isUserManagementLoading: false,
      stickyHeaders: false,
      memberArray: [],
      packageArray: [],
      statusArray: [],
    }
  },
  async mounted() {
    // this.setListCompanies([])
    this.resetState()
    await this.loadListCompaniesIndex()
  },

  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
      getListCompaniesData: 'listCompanies/getListCompaniesData',
    }),
  },

  methods: {
    ...mapActions({
      actionLoadListCompaniesData: 'listCompanies/fetchListCompaniesData',
    }),
    ...mapMutations({
      setListCompanies: 'listCompanies/setListCompaniesData',
    }),

    /**
     *  Function for format date
     * @param {string} date - date value
     * @param {string} format - format type
     */
    formatDate(date, format) {
      return formatDate(date, format)
    },

    /**
     *  Function to sort table
     * @param {string} field - field type
     * @param {string} order - order type
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     *  Function to change pagination
     * @param {number} page
     */
    onPageChange(page) {
      this.page = page
      this.loadListCompaniesIndex()
    },

    /**
     *  Function to load filter
     */
    async loadFilter() {
      this.resetState()
      this.setListCompanies([])
      this.perPage = 6
      await this.loadListCompaniesIndex()
    },

    /**
     *  Function to reset filter state
     *  @param {bool} isFiltering - to determine if fetch use filter or not
     */
    async resetState(isFiltering) {
      this.page = 0
      this.lastPage = 0
      this.setListCompanies([])
      await this.loadListCompaniesIndex(isFiltering)
    },

    /**
     *  Function to reset filter state
     *  @param {bool} isFiltering - to determine if fetch use filter or not
     */
    async resetFilter(isFiltering) {
      this.page = 0
      this.lastPage = 0
      await this.loadListCompaniesIndex(isFiltering)
    },

    /**
     *  Handle to reset all filter state
     */
    async resetStateFilter() {
      this.perPage = 6
      this.statusArray = []
      this.packageArray = []
      this.statuses.forEach((el) => (el.isSelected = false))
      this.packageFilter.forEach((el) => (el.isSelected = false))
      this.resetFilter()
      this.setListCompanies([])
      await this.loadListCompaniesIndex()
    },

    /**
     *  Function to apply filter via search
     * @param {string} evt
     */
    async searchIconClick(evt) {
      this.perPage = 1000
      await this.resetState(evt)
      if (this.search == 0) {
        this.perPage = 6
      }
    },

    /**
     *  Handle for open filter
     */
    openFilter() {
      this.isFilterOpen = true
    },

    /**
     *  Handle for close filter
     */
    closeFilter() {
      this.isFilterOpen = false
    },

    /**
     *  Function to fetch company list
     * @param {bool} isFiltering - to determine if fetch use filter or not
     */
    async loadListCompaniesIndex(isFiltering) {
      try {
        const params = {
          perPage: this.perPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: isFiltering,
          filterPackage: this.packageArray[0] ? this.packageArray[0] : null,
          filterPackage2: this.packageArray[1] ? this.packageArray[1] : null,
          filterPackage3: this.packageArray[2] ? this.packageArray[2] : null,
          filterPackage4: this.packageArray[3] ? this.packageArray[3] : null,
          filterStatus: this.statusArray[0] ? this.statusArray[0] : null,
          filterStatus2: this.statusArray[1] ? this.statusArray[1] : null,
        }

        if (isFiltering) {
          params.isFiltering = true
          params.page = this.page
        } else {
          params.page = ++this.page
        }

        const response = await this.actionLoadListCompaniesData(params)

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (e) {
        console.log(e)
      }
    },

    /**
     *  HandleClick for change filter
     *  @param {object} event - listener for change filter
     */
    filterBy(event) {
      let index = event.index
      const value =
        event.type === 'status'
          ? this.statusArray.indexOf(`filter_status[]=${event.item.value}`)
          : this.packageArray.indexOf(`filter_package[]=${event.item.value}`)

      if (value > -1) {
        this.addFilter({ value, index, type: event.type })
      } else {
        this.removeFilter({ value: event.item.value, index, type: event.type })
      }
    },

    /**
     *  Add filter to array
     *  @param {object} param
     */
    addFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.splice(value, 1)
        this.statuses[index].isSelected = false
      } else {
        this.packageArray.splice(value, 1)
        this.packageFilter[index].isSelected = false
      }
    },

    /**
     *  Remove filter in array
     *  @param {object} param
     */
    removeFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.push(`filter_status[]=${value}`)
        this.statuses[index].isSelected = true
      } else {
        this.packageArray.push(`filter_package[]=${value}`)
        this.packageFilter[index].isSelected = true
      }
    },

    /**
     *  Handle for clear filter by filter type
     * @param {string} type - filter type
     */
    async clearFilter(type) {
      if (type === 'status') {
        this.statusArray = []
        this.statuses.forEach((el) => (el.isSelected = false))
        this.filterStatus = []
      } else {
        this.packageArray = []
        this.packageFilter.forEach((el) => (el.isSelected = false))
        this.filterPackage = []
      }
      this.setListCompanies([])
      this.resetFilter()
      this.perPage = 6
      await this.loadListCompaniesIndex()
    },
  },
}
</script>
